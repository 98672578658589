import React, { useEffect, useState } from "react";
import { useGetAllGalleryRecordForSpecificSchoolMutation } from "../api/APIs/galleryApi";

const getCompleteUrl = (imageName) => {
  return `${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${imageName}`;
};

const Gallery = () => {
  const [galleryData, setGalleryData] = useState([]);
  const [getAllGalleryRecordForSpecificSchool] =
    useGetAllGalleryRecordForSpecificSchoolMutation();
  const fetchAndSetData = async () => {
    const galleryResponse = await getAllGalleryRecordForSpecificSchool().then(
      (response) => response.data?.data
    );
    if (galleryResponse !== undefined) {
      setGalleryData(galleryResponse);
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);
  return (
    <>
      <div className='lg:w-[470px] mx-auto my-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">GALLERY</h2>
        <h3 className='text__para text-center' data-aos="fade-up" data-aos-duration="1500" >
          Some glimpses of us
        </h3>
      </div>
      <div className="section-container lg:px-16 px-4 lg:py-12 py-10 space-y-7">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-7 place-items-center">
          {galleryData.map((gallery) => {
            console.log(getCompleteUrl(gallery.image));
            return (
              <div
                className="w-full lg:aspect-[5/5] md:aspect-[5/4] aspect-[5/3]"
                key={gallery._id}
              >
                <img
                  className="w-full h-full object-cover"
                  src={getCompleteUrl(gallery.image)}
                  alt=""
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Gallery;
