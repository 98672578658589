import React, { useEffect, useState } from 'react';
import { useGetAllSchoolWebsiteParentsInformationForSpecificSchoolMutation } from '../api/APIs/schoolWebsiteParentsInformationApi';
import HtmlParser from '../components/HtmlParser';

const ForParents = () => {
  const [forParents, setForParents] = useState([]);

  const [getAllSchoolWebsiteParentsInformationForSpecificSchool] =
    useGetAllSchoolWebsiteParentsInformationForSpecificSchoolMutation();

  const fetchAndSetData = async () => {
    const forParentsResponse =
      await getAllSchoolWebsiteParentsInformationForSpecificSchool().then(
        (response) => response.data?.data
      );
    if (forParentsResponse) {
      setForParents(forParentsResponse);
    }
  };
  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className="lg:w-[470px] mx-auto my-12">
        <h2
          className="heading text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          OUR TESTIMONIAL
        </h2>
      </div>
      <div className="section-container lg:px-16 px-4 lg:py-16 py-5">
        {forParents.map((parentsInfo) => {
          return (
            <div className="section">
              <div className="md:w-[50%] md:aspect-[5/4]">
                <img
                  className="w-full h-full object-contain"
                  src={`${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${parentsInfo.coverImage}`}
                  alt=""
                />
              </div>
              <div className="md:w-[50%]">
                <h3 className="pb-4">{parentsInfo.title}</h3>
                <p>
                  <HtmlParser>{parentsInfo.description ?? ''}</HtmlParser>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ForParents;
