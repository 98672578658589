import React from 'react';
import { Link } from 'react-router-dom';
const AdmissionProcess = () => {
  return (
    <>
      <div className="lg:w-[470px] mx-auto my-12">
        <h2
          className="heading text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          ADMISSION PROCESS
        </h2>
        <h3
          className="text__para text-center"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          How to apply
        </h3>
      </div>
      <section className="section-container lg:px-20 px-4 lg:py-16">
        <h3>Admission On-boarding</h3>
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-x-7 lg:gap-y-16 gap-y-5 mt-10">
          <div className="course-card" data-aos="zoom-in">
            <div className="w-full aspect-[5/3]">
              <img
                className="w-full h-full object-cover"
                src="https://ratobangala.edu.np/web/image/2350/Unit%20II.JPG"
                alt="admission img"
              />
            </div>
            <div className="p-4">
              <div className="flex items-center primary-text gap-2 pb-4">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                    />
                  </svg>
                </span>
                <span className="pt-1">2024-02-20</span>
              </div>
              <h4>Commerce</h4>
              <p className="py-3 leading-6">
                New admission for the academic year 2024-2025 for Grades XI and
                XII is open. Parents seeking admission for their child in these
                Grades must fill out and submit the online form. Limited seats
                ....
              </p>
              <div className="py-4">
                <Link
                  to="#"
                  className="bg-white text-[#1e1e4b] px-3 py-2 border-[#1e1e4b] border font-semibold hover:bg-[#1e1e4b] hover:text-white"
                >
                  Submit Appication
                </Link>
              </div>
            </div>
          </div>
          <div className="course-card" data-aos="zoom-in">
            <div className="w-full aspect-[5/3]">
              <img
                className="w-full h-full object-cover"
                src="https://ratobangala.edu.np/web/image/2350/Unit%20II.JPG"
                alt="admission img"
              />
            </div>
            <div className="p-4">
              <div className="flex items-center primary-text gap-2 pb-4">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                    />
                  </svg>
                </span>
                <span className="pt-1">2024-02-20</span>
              </div>
              <h4>Commerce</h4>
              <p className="py-3 leading-6">
                New admission for the academic year 2024-2025 for Grades XI and
                XII is open. Parents seeking admission for their child in these
                Grades must fill out and submit the online form. Limited seats
                ....
              </p>
              <div className="py-4">
                <Link
                  to="#"
                  className="bg-white text-[#1e1e4b] px-3 py-2 border-[#1e1e4b] border font-semibold hover:bg-[#1e1e4b] hover:text-white"
                >
                  Submit Appication
                </Link>
              </div>
            </div>
          </div>
          <div className="course-card" data-aos="zoom-in">
            <div className="w-full aspect-[5/3]">
              <img
                className="w-full h-full object-cover"
                src="https://ratobangala.edu.np/web/image/2350/Unit%20II.JPG"
                alt="admission img"
              />
            </div>
            <div className="p-4">
              <div className="flex items-center primary-text gap-2 pb-4">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                    />
                  </svg>
                </span>
                <span className="pt-1">2024-02-20</span>
              </div>
              <h4>Commerce</h4>
              <p className="py-3 leading-6">
                New admission for the academic year 2024-2025 for Grades XI and
                XII is open. Parents seeking admission for their child in these
                Grades must fill out and submit the online form. Limited seats
                ....
              </p>
              <div className="py-4">
                <Link
                  to="#"
                  className="bg-white text-[#1e1e4b] px-3 py-2 border-[#1e1e4b] border font-semibold hover:bg-[#1e1e4b] hover:text-white"
                >
                  Submit Appication
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdmissionProcess;
