import React, { useEffect, useState } from "react";
import { useGetAllSchoolWebsiteScholarshipDetailsForSpecificSchoolMutation } from "../api/APIs/schoolWebsiteScholarshipDetailsApi";
import HtmlParser from "../components/HtmlParser";
import { Link } from "react-router-dom";

const Scholarship = () => {
  const [scholarshipData, setScholarshipData] = useState({});
  const [getAllSchoolWebsiteScholarshipDetailsForSpecificSchool] =
    useGetAllSchoolWebsiteScholarshipDetailsForSpecificSchoolMutation();

  const fetchAndSetData = async () => {
    const scholarshipResponse =
      await getAllSchoolWebsiteScholarshipDetailsForSpecificSchool().then(
        (response) => response.data?.data
      );
    if (scholarshipResponse !== undefined) {
      setScholarshipData(scholarshipResponse);
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className='lg:w-[470px] mx-auto mt-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">SCHOLARSHIPS</h2>
      </div>
      <div className="section-container lg:px-16 px-4 lg:py-12 py-10 space-y-7">
        <div className="flex md:flex-row flex-col items-center lg:gap-10 gap-7">
          <div className="md:w-[50%]">
            <img src="./images/scholarship.jpg" alt="scholarship" />
          </div>
          <div className="md:w-[50%] self-start">
            <h2 className="pb-4">Scholarship News</h2>
            <p>
              <HtmlParser>{scholarshipData.description ?? ""}</HtmlParser>
              <div className="p-4 ">
                <Link
                  to={scholarshipData.formLink}
                  className="bg-white text-[#fda507] px-3 py-3 border-[#fda507] border font-semibold hover:bg-[#fda507] hover:text-white"
                >
                  Apply Now
                </Link>
              </div>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Scholarship;
