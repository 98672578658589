
import { baseApi } from "./baseApi";

const schoolWebsiteParentsInformationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({

    // * GET ALL schoolWebsiteParentsInformation FOR SPECIFIC SCHOOL --working
    getAllSchoolWebsiteParentsInformationForSpecificSchool: builder.mutation({
      query: () => {
        return {
          url: `schoolWebsiteParentsInformation/get-all-for-school`,
          method: "POST",
          formData: true,
          headers: {
            "x-school-id": process.env.REACT_APP_SCHOOLID,
            "x-school-unique-id": process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),

    // get single
    getSingleSchoolWebsiteParentsInformation: builder.mutation({
      query: (payload) => {
        return {
          url: `schoolWebsiteParentsInformation/${payload.id}`,
          method: "GET",
          formData: true,
          headers: {
            "x-school-id": process.env.REACT_APP_SCHOOLID,
            "x-school-unique-id": process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),
  }),
});

export const {
  useGetSingleSchoolWebsiteParentsInformationMutation,
  useGetAllSchoolWebsiteParentsInformationForSpecificSchoolMutation,
} = schoolWebsiteParentsInformationApi;
