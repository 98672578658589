import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useGetAllSchoolWebsiteInformationForSpecificSchoolMutation } from '../api/APIs/schoolWebsiteInformationApi';
import config from '../utils/config';
import {
  usePostSchoolAdmissionFormMutation,
  useGetSingleSchoolAdmissionFormClassNamesMutation,
} from '../api/APIs/schoolWebsiteAdmissionFormApi';

const Header = () => {
  const [infoResponse] = useGetSingleSchoolAdmissionFormClassNamesMutation();
  const [classNames, setClassNames] = useState([]);
  const [attachments, setAttachments] = useState({});
  const [admissionIds, setAdmissionIds] = useState({});
  // Fetch and set class data
  const fetchAndSetAdmissionOpenClasses = async () => {
    try {
      const response = await infoResponse();
      const informationResponse = response.data?.data;
      console.log('informationResponse:', informationResponse);

      const classNames = informationResponse.map((info) => info.className);
      const attachments = informationResponse.reduce((acc, info) => {
        if (!acc[info.className]) acc[info.className] = [];
        acc[info.className].push(info.attachment);
        return acc;
      }, {});
      const admissionIds = informationResponse.reduce((acc, info) => {
        acc[info.className] = info.admissionId;
        return acc;
      }, {});

      setClassNames(classNames);
      setAttachments(attachments);
      setAdmissionIds(admissionIds);
    } catch (error) {
      console.error('Error fetching class names:', error);
    }
  };

  useEffect(() => {
    fetchAndSetAdmissionOpenClasses();
  }, []);

  const [informationData, setInformationData] = useState({});
  const [getAllSchoolWebsiteInformationForSpecificSchool] =
    useGetAllSchoolWebsiteInformationForSpecificSchoolMutation();

  const fetchAndSetData = async () => {
    const informationResponse =
      await getAllSchoolWebsiteInformationForSpecificSchool().then(
        (response) => response.data?.data
      );
    if (informationResponse) {
      setInformationData(informationResponse);
    }
  };
  useEffect(() => {
    fetchAndSetData();
  }, []);
  const location = useLocation();
  const [menu, showMenu] = useState(false);
  const [dropdown, showDropdown] = useState(false);

  if (location.pathname === '/login') return null;
  return (
    <>
      <header className="w-full header list-none">
        {/* top header */}
        <div className="text-xs text-white min-h-10 bg-[#1e1e4b] flexPack lg:flex-row flex-col flex-wrap lg:px-[8%] px-2 py-1">
          <div className="flexPack flex-wrap sm:gap-10 gap-4">
            <li>
              <strong>CALL</strong> {informationData.contact}
            </li>
            <ul className="flex gap-4">
              {informationData.facebookUrl ? (
                <li>
                  <Link to={informationData.facebookUrl} target="_blank">
                    <ion-icon name="logo-facebook"></ion-icon>
                  </Link>
                </li>
              ) : (
                ''
              )}
              {informationData.twitterUrl ? (
                <li>
                  <Link to={informationData.twitterUrl} target="_blank">
                    <ion-icon name="logo-twitter"></ion-icon>
                  </Link>
                </li>
              ) : (
                ''
              )}
              {informationData.linkedlnUrl ? (
                <li>
                  <Link to={informationData.linkedlnUrl} target="_blank">
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </Link>
                </li>
              ) : (
                ''
              )}
              {informationData.instagramUrl ? (
                <li>
                  <Link to={informationData.instagramUrl} target="_blank">
                    <ion-icon name="logo-instagram"></ion-icon>
                  </Link>
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
          <ul className="top-links flex justify-center flex-wrap lg:gap-6 gap-3 lg:py-0 py-2 uppercase pl-5">
            <li>
              <Link to="/notice">notice</Link>
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
            {/* <li>
              <Link to="/admission-process">Admission Process</Link>
            </li> */}
            <li>
              <Link to="/scholarship">SCHOLARSHIP</Link>
            </li>
            <li>
              <Link to="/terms-condition">TERMS & CONDITIONS</Link>
            </li>
            <li>
              <Link
                to="https://www.yourschoolsoftware.com/login"
                data-toggle="modal"
                data-target="#loginModal"
              >
                login
              </Link>
            </li>
            <li className="apply-dropdown">
              <Link className="apply-dropdown-link relative bg-white text-[#1e1e4b] py-1 px-2 rounded-md">
                Apply Now
              </Link>
              <div class="apply-dropdown-menu rounded">
                {classNames.length > 0 ? (
                  classNames.map((className, index) => {
                    console.log('className:', className);
                    return (
                      <Link
                        key={index}
                        to={`/apply-for-admission-form/${admissionIds[className]}`}
                      >
                        <div className="p-2 rounded min-w-24   text-[#1e1e4b] hover:bg-[#1e1e4b] hover:text-[#ffffff]">
                          {className}
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <div className="p-2 rounded  text-[#1e1e4b] hover:bg-[#1e1e4b] hover:text-[#ffffff]">
                    <Link key={0} to={`#`}>
                      No Admission Open
                    </Link>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      </header>
      {/* navigation */}
      <nav className={`navigation sticky top-0 z-50`}>
        <div className="w-full text-[#1e1e4b] font-semibold from-neutral-50 lg:py-0 py-3 mx-auto flexPack lg:px-[9%] px-6">
          <Link className="logo" to="/">
            <img
              className="md:w-40 w-36 object-contain max-h-20"
              src={config.getUrlFromName(informationData)}
              alt="your-school-logo"
            />
          </Link>
          <div
            onClick={() => showMenu(!menu)}
            id="hamburger"
            className="lg:hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2.0"
              stroke="currentColor"
              class="w-9 h-9"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </div>
          <ul
            id="nav"
            className={`navLinks h-full lg:flex items-center justify-between gap-8 pl-2
            ${menu ? 'left-0' : 'left-[-15rem]'}
            `}
          >
            <div onClick={() => showMenu(!menu)} className="lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-white ml-auto -mt-3 primary-bg mr-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>

            <li className={location.pathname === '/' ? 'active' : ''}>
              <Link to="/">HOME</Link>
            </li>
            <li
              className={`dropdown relative ${
                location.pathname === '/about' ? 'active' : ''
              }`}
            >
              <Link onClick={() => showDropdown(!dropdown)} className="pages">
                ABOUT US
              </Link>
              <div
                class={`dropdown-menu lg:space-y-4
              ${dropdown ? 'block' : 'hidden'}
              `}
              >
                <li>
                  <Link class="dropdown-item" to="/history">
                    History
                  </Link>
                </li>

                <li>
                  <Link class="dropdown-item" to="/team">
                    Team
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/values">
                    Values
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/partners">
                    Partners
                  </Link>
                </li>
              </div>
            </li>
            <li className={location.pathname === '/programme' ? 'active' : ''}>
              <Link to="/programme">PROGRAMME</Link>
            </li>
            <li className={location.pathname === '/events' ? 'active' : ''}>
              <Link to="/events">EVENTS</Link>
            </li>
            <li
              className={`dropdown relative ${
                location.pathname === '/academics' ? 'active' : ''
              }`}
            >
              <Link onClick={() => showDropdown(!dropdown)} className="pages">
                Academics
              </Link>
              <div
                class={`dropdown-menu lg:space-y-4
              ${dropdown ? 'block' : 'hidden'}
              `}
              >
                <li>
                  <Link class="dropdown-item" to="/schools">
                    School Blogs
                  </Link>
                </li>

                <li>
                  <Link class="dropdown-item" to="/departments">
                    Departments
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/extracurricular-activities">
                    Extracurricular Activities
                  </Link>
                </li>
              </div>
            </li>
            <li
              className={`dropdown relative ${
                location.pathname === '/pages' ? 'active' : ''
              }`}
            >
              <Link onClick={() => showDropdown(!dropdown)} className="pages">
                PAGES
              </Link>
              <div
                class={`dropdown-menu lg:space-y-4
              ${dropdown ? 'block' : 'hidden'}
              `}
              >
                <li>
                  <Link class="dropdown-item" to="/for-parents">
                    Testimonial
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/for-students">
                    For Students
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/for-teachers">
                    For Teachers
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/notice">
                    Notice
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/gallery">
                    Gallery
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/scholarship">
                    Scholarship
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/facilities">
                    Facilities
                  </Link>
                </li>
              </div>
            </li>
            <li className={location.pathname === '/contact' ? 'active' : ''}>
              <Link to="/contact">CONTACT</Link>
            </li>
            <li className={location.pathname === '/calendar' ? 'active' : ''}>
              <Link to="/calendar">Calendar</Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
