import React from 'react';
import FaqItem from './FaqItem';

const FaqList = () => {
  const faqs = [
    {
      question: 'How do I apply for admission?',
      answer:
        "You can apply for admission by clicking on the 'Apply Now' button on the homepage. Follow the instructions provided on the application page.",
    },
    {
      question: 'Where can I find the latest scholarship news?',
      answer:
        "The latest scholarship news can be found in the 'Scholarship News' section on the homepage. Click on the 'Scholarship' link for more details.",
    },
    {
      question: 'How do I access the notice board?',
      answer:
        "You can access the notice board by clicking on the 'Our Notice Boards' link on the homepage.",
    },
    {
      question: 'What values does the school promote?',
      answer:
        "Our school promotes values such as integrity, respect, and excellence. More details can be found in the 'Our Values' section on the homepage.",
    },
    {
      question: 'How do I contact the school for more information?',
      answer:
        "You can contact the school for more information by visiting the 'Contact Us' page. The link is available in the footer of the website.",
    },
  ];

  return (
    <ul className="mt-[28px]">
      {faqs.map((item, index) => (
        <FaqItem item={item} key={index} />
      ))}
    </ul>
  );
};

export default FaqList;
