import { baseApi } from './baseApi';

const schoolWebsiteAdmissionFormApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // * GET ALL schoolWebsiteHero FOR SPECIFIC SCHOOL --working
    postSchoolAdmissionForm: builder.mutation({
      query: (payload) => {
        console.log('Payload to query', payload);
        return {
          url: `admissionApplication/submitApplication/${payload.admissionId}`,
          method: 'POST',
          formData: true,
          body: payload.formData,
          headers: {
            'x-school-id': process.env.REACT_APP_SCHOOLID,
            'x-school-unique-id': process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),

    // this api gets all list of class names & names of attached form to be filled for admission
    // can be used for dropdown list of classes in the admission and downloading the form for that class
    getSingleSchoolAdmissionFormClassNames: builder.mutation({
      query: () => {
        console.log('getSingleSchoolAdmissionFormClassNames');
        return {
          url: `admission/getAllAdmissionFormClassNames`,
          method: 'GET',
          formData: true,
          headers: {
            'x-school-id': process.env.REACT_APP_SCHOOLID,
            'x-school-unique-id': process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),
  }),
});

export const {
  usePostSchoolAdmissionFormMutation,
  useGetSingleSchoolAdmissionFormClassNamesMutation,
} = schoolWebsiteAdmissionFormApi;
