import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UpcomingEvents from '../components/UpcomingEvents';
import { useGetAllSchoolWebsiteHeroForSpecificSchoolMutation } from '../api/APIs/schoolWebsiteHeroApi';
import { useGetAllSchoolWebsiteHomePageSecondForSpecificSchoolMutation } from '../api/APIs/schoolWebsiteHomePageSecondApi';
import HtmlParser from '../components/HtmlParser';
import FaqList from '../components/FaqList';
import EmblaCarou from '../components/EmblaCarou';
import { useGetAllSchoolWebsiteFacilitiesForSpecificSchoolMutation } from '../api/APIs/schoolWebsiteTestimonialApi';
import SchoolStats from '../components/SchoolStats';

const Home = () => {
  const OPTIONS = { align: 'start' };

  const [heroData, setHeroData] = useState({});
  const [homePageSecondData, setHomePageSecondData] = useState({});
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [heroImageUrl, setHeroImageUrl] = useState(
    'https://i.ibb.co/vVnqvfJ/sss.jpg'
  );
  const [homePageSecondImageUrl, setHomePageSecondImageUrl] = useState(
    'https://i.ibb.co/vVnqvfJ/sss.jpg'
  );

  const [getTestimonials] =
    useGetAllSchoolWebsiteFacilitiesForSpecificSchoolMutation();
  const [getAllSchoolWebsiteHeroForSpecificSchool] =
    useGetAllSchoolWebsiteHeroForSpecificSchoolMutation();
  const [getAllSchoolWebsiteHomePageSecondForSpecificSchool] =
    useGetAllSchoolWebsiteHomePageSecondForSpecificSchoolMutation();

  useEffect(() => {
    const fetchAndSetData = async () => {
      try {
        const heroResponse = await getAllSchoolWebsiteHeroForSpecificSchool();
        if (heroResponse?.data?.data) {
          setHeroData(heroResponse.data.data);
          setHeroImageUrl(
            heroResponse.data.data.coverImage
              ? `${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${heroResponse.data.data.coverImage}`
              : heroImageUrl
          );
        }

        const homePageSecondResponse =
          await getAllSchoolWebsiteHomePageSecondForSpecificSchool();
        if (homePageSecondResponse?.data?.data) {
          setHomePageSecondData(homePageSecondResponse.data.data);
          setHomePageSecondImageUrl(
            homePageSecondResponse.data.data.coverImage
              ? `${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${homePageSecondResponse.data.data.coverImage}`
              : homePageSecondImageUrl
          );
        }

        const testimonialsResponse = await getTestimonials();
        if (testimonialsResponse?.data?.data) {
          setTestimonialsData(testimonialsResponse.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAndSetData();
  }, []);

  return (
    <>
      <div
        className="w-full h-screen bg-cover bg-center flex items-center justify-center px-4 md:px-8 lg:px-16"
        style={{ backgroundImage: `url(${heroImageUrl})` }}
      >
        <div className="bg-white bg-opacity-80 p-6 md:p-12 rounded-lg text-center max-w-3xl">
          <h1
            className="text-[#1e1e4b] text-2xl md:text-4xl font-bold"
            data-aos="fade-up"
          >
            {heroData.heading}
          </h1>
          <p className="py-4 text-sm md:text-lg" data-aos="fade-up">
            <HtmlParser>{heroData.subHeading || ''}</HtmlParser>
          </p>
          <a
            href={`${process.env.REACT_APP_CLIENTURL}/apply-for-admission-form/*`}
            className="bg-[#1e1e4b] text-white px-6 py-3 font-semibold hover:bg-opacity-80 transition duration-300"
            data-aos="fade-up"
          >
            Apply Now
          </a>
        </div>
      </div>

      <SchoolStats />

      <h2 className="text-center text-4xl font-bold py-8">
        We focus on your future
      </h2>

      <section className="section-container flex flex-col lg:flex-row items-center gap-10 px-6 px-16 pb-16">
        <img
          src={homePageSecondImageUrl}
          alt="banner"
          className="w-full max-w-md lg:max-w-lg rounded-lg shadow-lg"
          data-aos="zoom-in"
        />
        <div className="grid md:grid-cols-2 gap-6 w-full max-w-3xl">
          {[
            {
              link: '/scholarship',
              title: 'Scholarship News',
              content: homePageSecondData.scholarshipNews,
            },
            {
              link: '/notice',
              title: 'Our Notice Boards',
              content: homePageSecondData.noticeBoard,
            },
            {
              link: '/values',
              title: 'Our Values',
              content: homePageSecondData.ourValues,
            },
            {
              link: '/admission-process',
              title: 'Admission Now',
              content: homePageSecondData.admissionNow,
            },
          ].map((item, index) => (
            <Link
              to={item.link}
              key={index}
              className="p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 hover:bg-gray-100"
            >
              <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
              <p className="text-sm">
                <HtmlParser>{item.content || ''}</HtmlParser>
              </p>
            </Link>
          ))}
        </div>
      </section>

      {testimonialsData.length > 0 && (
        <section className="hidden md:block section-container text-center py-16">
          <h2 className="text-4xl font-bold mb-6 text-gray-800">
            What People Say
          </h2>
          <EmblaCarou slides={testimonialsData} options={OPTIONS} />
        </section>
      )}

      <section className="section-container px-6 pb-4">
        <h2 className="text-center text-4xl font-bold mb-6">
          Frequently Asked Questions
        </h2>
        <FaqList />
      </section>
    </>
  );
};

export default Home;
