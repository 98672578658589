import React, { useEffect, useState } from "react";
import CourseCard from "../components/CourseCard";
import { useGetAllSchoolWebsiteProgrammeForSpecificSchoolMutation } from "../api/APIs/schoolWebsiteProgrammeApi";

const Courses = () => {
  const [programmeData, setProgrammeData] = useState([]);
  const [getAllSchoolWebsiteProgrammeForSpecificSchool] =
    useGetAllSchoolWebsiteProgrammeForSpecificSchoolMutation();

  const fetchAndSetData = async () => {
    const programmeResponse =
      await getAllSchoolWebsiteProgrammeForSpecificSchool().then(
        (response) => response.data?.data
      );
    if (programmeResponse !== undefined) {
      setProgrammeData(programmeResponse);
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className='lg:w-[470px] mx-auto mt-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">OUR COURSES</h2>
        <h3 className='text__para text-center' data-aos="fade-up" data-aos-duration="1500" >
          Programmes we offer
        </h3>
      </div>
      <div className="background">
        <div className="section-container  lg:px-16 px-4 lg:py-16 py-10 mt-10">
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-x-7 lg:gap-y-16 gap-y-5">
            {programmeData.map((programme) => {
              return (<CourseCard programme={programme} />);
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;
