import { baseApi } from "./baseApi";

const schoolWebsiteContactApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    //* POST schoolWebsiteContact --working
    postAddSchoolWebsiteContact: builder.mutation({
      query: (formData) => ({
        url: `schoolWebsiteContact/create`,
        method: "POST",
        body: formData,
        formData: true,
        headers: {
          "x-school-id": process.env.REACT_APP_SCHOOLID,
          "x-school-unique-id": process.env.REACT_APP_SCHOOLUNIQUEID,
        },
      }),
    }),
  }),
});

export const {
  usePostAddSchoolWebsiteContactMutation
} = schoolWebsiteContactApi;
