import React from "react";
import TeachersCard from "../components/TeachersCard";
const Events = () => {
  return (
    <>
      <div className='lg:w-[470px] mx-auto my-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">OUR TEACHERS</h2>
      </div>
      <div className="background">
        <div className="section-container lg:px-16 px-4 lg:py-16 py-10">
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-x-7 lg:gap-y-16 gap-y-5">
            <TeachersCard team={[]} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
