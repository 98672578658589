import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div className='lg:w-[470px] mx-auto my-12'>
        <h2 className='primary-text heading text-center'>TERMS & CONDITIONS</h2>
      </div>
      <div className="section-container lg:px-20 px-4 lg:py-10 py-7">
        <h3>Our terms of service</h3>
        <h5 className="mt-6 mb-3">License</h5>
        <p>
          Unless otherwise stated, [Your School Name] and/or its licensors own
          the intellectual property rights for all material on [Your School
          Name]. All intellectual property rights are reserved. You may access
          this from [Your School Name] for your own personal use subjected to
          restrictions set in these terms and conditions. You must not:
          Republish material from [Your School Name] Sell, rent or sub-license
          material from [Your School Name] Reproduce, duplicate or copy material
          from [Your School Name] Redistribute content from [Your School Name]
          This Agreement shall begin on the date hereof.
        </p>
        <h5 className="mt-6 mb-3">Content Liability</h5>
        <p>
          We shall not be held responsible for any content that appears on your
          website. You agree to protect and defend us against all claims that
          are rising on your website. No link(s) should appear on any website
          that may be interpreted as libelous, obscene or criminal, or which
          infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
        </p>
        <h5 className="mt-6 mb-3">Reservation of Rights</h5>
        <p>
          We reserve the right to request that you remove all links or any
          particular link to our website. You approve to immediately remove all
          links to our website upon request. We also reserve the right to amend
          these terms and conditions and it’s linking policy at any time. By
          continuously linking to our website, you agree to be bound to and
          follow these linking terms and conditions.
        </p>

        <h5 className="mt-6 mb-3">Removal of links from our website</h5>
        <p>
          If you find any link on our website that is offensive for any reason,
          you are free to contact and inform us at any moment. We will consider
          requests to remove links but we are not obligated to or so or to
          respond to you directly.
        </p>
        <p>
          We do not ensure that the information on this website is correct, we
          do not warrant its completeness or accuracy; nor do we promise to
          ensure that the website remains available or that the material on the
          website is kept up to date.
        </p>

        <h3 className="mt-6 mb-3">Our commitment to protecting your privacy</h3>
        <h5 className="mt-6 mb-3">INTRODUCTION</h5>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
          temporibus perspiciatis nemo vel nihil labore, omnis cupiditate,
          similique quibusdam quaerat ratione? Nulla animi error praesentium qui
          dignissimos quaerat incidunt velit laborum, perspiciatis maxime
          provident distinctio voluptatem, hic quo doloribus totam voluptas
          similique facere et laudantium. Ipsum non culpa ad iste.
        </p>
        <br />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab sed ad
          perferendis soluta a nemo praesentium? Excepturi ab fuga minus!
          Consequatur nesciunt aliquam, deleniti assumenda dolore explicabo
          tempora eius. Perferendis itaque, sequi animi sint reprehenderit
          voluptate similique laborum repellendus nobis autem, ullam mollitia
          tempora atque reiciendis consequuntur nesciunt est molestias.
        </p>
        <h5 className="mt-6 mb-3">SENSITIVE PERSONAL DATA</h5>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
          temporibus perspiciatis nemo vel nihil labore, omnis cupiditate,
          similique quibusdam quaerat ratione? Nulla animi error praesentium qui
          dignissimos quaerat incidunt velit laborum, perspiciatis maxime
          provident distinctio voluptatem, hic quo doloribus totam voluptas
          similique facere et laudantium. Ipsum non culpa ad iste.
        </p>
        <br />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab sed ad
          perferendis soluta a nemo praesentium? Excepturi ab fuga minus!
          Consequatur nesciunt aliquam, deleniti assumenda dolore explicabo
          tempora eius. Perferendis itaque, sequi animi sint reprehenderit
          voluptate similique laborum repellendus nobis autem, ullam mollitia
          tempora atque reiciendis consequuntur nesciunt est molestias.
        </p>
      </div>
    </>
  );
};

export default TermsAndConditions;
