import React, { useEffect, useState } from "react";
import { useGetAllSchoolWebsiteTeachersInformationForSpecificSchoolMutation } from "../api/APIs/schoolWebsiteTeachersInformationApi";
import HtmlParser from "../components/HtmlParser";

const ForTeachers = () => {
  const [forTeachers, setForTeachers] = useState([]);

  const [getAllSchoolWebsiteTeachersInformationForSpecificSchool] =
    useGetAllSchoolWebsiteTeachersInformationForSpecificSchoolMutation();

  const fetchAndSetData = async () => {
    const forTeachersResponse =
      await getAllSchoolWebsiteTeachersInformationForSpecificSchool().then(
        (response) => response.data?.data
      );
    if (forTeachersResponse) {
      setForTeachers(forTeachersResponse);
    }
  };
  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className='lg:w-[470px] mx-auto my-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">FOR TEACHERS</h2>
      </div>
      <div className="section-container lg:px-16 px-4 lg:py-16 py-5">
        {forTeachers.map((teachersInfo) => {
          return (
            <div className="section">
              <div className="md:w-[50%] md:aspect-[5/4]">
                <img
                  className="w-full h-full object-contain"
                  src={`${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${teachersInfo.coverImage}`}
                  alt=""
                />
              </div>
              <div className="md:w-[50%]">
                <h3 className="pb-4">{teachersInfo.title}</h3>
                <p>
                  <HtmlParser>{teachersInfo.description ?? ""}</HtmlParser>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ForTeachers;
