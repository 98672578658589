import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import config from '../utils/config';
import { useGetAllSchoolWebsiteInformationForSpecificSchoolMutation } from '../api/APIs/schoolWebsiteInformationApi';

const Footer = () => {
  const [informationData, setInformationData] = useState({});
  const [getAllSchoolWebsiteInformationForSpecificSchool] =
    useGetAllSchoolWebsiteInformationForSpecificSchoolMutation();

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    console.log('email', event.target.email.value);
  };

  useEffect(() => {
    const fetchAndSetData = async () => {
      const informationResponse =
        await getAllSchoolWebsiteInformationForSpecificSchool().then(
          (response) => response.data?.data
        );
      setInformationData(informationResponse);
    };

    fetchAndSetData();
  }, []);

  const location = useLocation();
  if (location.pathname === '/login') return null;

  return (
    <>
      <section>
        <iframe
          title="School Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3566.0436953821873!2d87.9934513755042!3d26.647083376806492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e5ba613359d731%3A0xf0beb5299b221e13!2sDevi%20Secondary%20School!5e0!3m2!1sen!2snp!4v1739362033212!5m2!1sen!2snp"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </section>

      <footer className="primary-bg text-white py-8">
        <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between">
          {/* Left Section */}
          <div className="flex flex-col items-center md:items-start text-center md:text-left space-y-4">
            <img
              className="w-40 h-40 object-contain"
              src={config.getUrlFromName(informationData)}
              alt="School Logo"
            />
            <p className="text-sm">
              {informationData.school?.school_name || 'Devi Secondary School'}
              <br />
              {informationData.school?.street || 'Street Name'}{' '}
              {informationData.school?.postal_code || ''},{' '}
              {informationData.school?.state || ''}
              <br />
              {informationData.school?.school_location || 'Jhapa, Nepal'}
            </p>
            {/* Social Media Links */}
            <ul className="flex space-x-4">
              {informationData.facebookUrl && (
                <li>
                  <a
                    href={informationData.facebookUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ion-icon
                      name="logo-facebook"
                      className="text-2xl"
                    ></ion-icon>
                  </a>
                </li>
              )}
              {informationData.twitterUrl && (
                <li>
                  <a
                    href={informationData.twitterUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ion-icon
                      name="logo-twitter"
                      className="text-2xl"
                    ></ion-icon>
                  </a>
                </li>
              )}
              {informationData.linkedlnUrl && (
                <li>
                  <a
                    href={informationData.linkedlnUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ion-icon
                      name="logo-linkedin"
                      className="text-2xl"
                    ></ion-icon>
                  </a>
                </li>
              )}
              {informationData.instagramUrl && (
                <li>
                  <a
                    href={informationData.instagramUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ion-icon
                      name="logo-instagram"
                      className="text-2xl"
                    ></ion-icon>
                  </a>
                </li>
              )}
            </ul>
          </div>

          {/* Right Section */}
          <div className="mt-8 md:mt-0 w-full md:w-2/3">
            <h3 className="text-2xl font-bold">Helpful Links</h3>
            <div className="w-24 h-1 bg-white my-2"></div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
              <ul className="space-y-2">
                <li>
                  <Link to="/notice" className="hover:text-yellow-500">
                    Notices
                  </Link>
                </li>
                <li>
                  <Link to="/gallery" className="hover:text-yellow-500">
                    Gallery
                  </Link>
                </li>
                <li>
                  <Link to="/facilities" className="hover:text-yellow-500">
                    Facilities
                  </Link>
                </li>
              </ul>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/extracurricular-activities"
                    className="hover:text-yellow-500"
                  >
                    ECAs
                  </Link>
                </li>
                <li>
                  <Link to="/departments" className="hover:text-yellow-500">
                    Departments
                  </Link>
                </li>
                <li>
                  <Link to="/scholarship" className="hover:text-yellow-500">
                    Scholarship
                  </Link>
                </li>
              </ul>
              <ul className="space-y-2">
                <li>
                  <Link to="/history" className="hover:text-yellow-500">
                    History
                  </Link>
                </li>
                <li>
                  <Link to="/team" className="hover:text-yellow-500">
                    Team
                  </Link>
                </li>
                <li>
                  <Link to="/values" className="hover:text-yellow-500">
                    Values
                  </Link>
                </li>
              </ul>
            </div>

            {/* Newsletter Section */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold">
                Subscribe to Our Newsletter!
              </h3>
              <form
                onSubmit={handleEmailSubmit}
                className="flex flex-col sm:flex-row gap-2 mt-2"
              >
                <input
                  name="email"
                  type="email"
                  className="w-full sm:w-[70%] p-3 text-black rounded-md"
                  placeholder="Enter your email"
                />
                <button
                  type="submit"
                  className="bg-yellow-500 text-white px-6 py-3 rounded-md hover:bg-yellow-600 transition"
                >
                  Join Now
                </button>
              </form>
            </div>

            {/* Copyright */}
            <div className="mt-6 text-center md:text-left">
              <p className="text-sm">
                © {new Date().getFullYear()} Devi Secondary School. All rights
                reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
