import React from 'react';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from './EmblaCarouselArrowButtons';
import useEmblaCarousel from 'embla-carousel-react';
import { Quote } from 'lucide-react';
import HtmlParser from '../components/HtmlParser';

const EmblaCarousel = ({ options, slides }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    ...options,
    containScroll: 'trimSnaps', // Ensures smooth scrolling
    align: 'start', // Aligns slides properly
  });

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);
  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <div className="relative">
      {/* Carousel Viewport */}
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex space-x-4">
          {slides.map((testimonial, index) => (
            <div
              key={index}
              className="embla__slide flex-[0_0_auto] w-full sm:w-3/4 md:w-1/2 lg:w-1/3"
            >
              <div className="bg-white shadow-lg rounded-2xl p-6 border border-gray-200">
                <Quote className="text-gray-300 w-10 h-10 mb-3" />
                <p className="text-gray-700 text-lg mb-4">
                  <HtmlParser>{testimonial.testimonialDescription}</HtmlParser>
                </p>
                <div className="mt-4 flex items-center">
                  <img
                    src={`${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${testimonial.coverImage}`}
                    alt="testimonial"
                    className="w-12 h-12 mr-3 rounded-full mt-2 object-cover"
                  />
                  <div>
                    <h4 className="font-semibold text-gray-900">
                      {testimonial.name}
                    </h4>
                    <p className="text-sm text-gray-500">{testimonial.date}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between items-center mt-4">
        <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
        <div className="flex space-x-2">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={`w-3 h-3 rounded-full ${
                index === selectedIndex ? 'bg-gray-800' : 'bg-gray-400'
              }`}
            />
          ))}
        </div>
        <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
      </div>
    </div>
  );
};

export default EmblaCarousel;
