import React, { useEffect, useState } from "react";
import BoardCard from "../components/BoardCard";
import TeachersCard from "../components/TeachersCard";
import StaffCard from "../components/StaffCard";
import { useGetAllSchoolWebsiteTeamForSpecificSchoolMutation } from "../api/APIs/schoolWebsiteTeamApi";

const Team = () => {
  const [teamData, setTeamData] = useState([]);
  const [getAllSchoolWebsiteTeamForSpecificSchool] =
    useGetAllSchoolWebsiteTeamForSpecificSchoolMutation();

  const fetchAndSetData = async () => {
    const teamResponse = await getAllSchoolWebsiteTeamForSpecificSchool();
    if (teamResponse?.data?.data) {
      setTeamData(teamResponse.data.data);
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, [])

  return (
    <>
      <div className='lg:w-[470px] mx-auto mt-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">OUR TEAM</h2>
      </div>
      <div className="section-container lg:px-20 px-4 lg:py-10 py-7">
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-x-7 lg:gap-y-16 gap-y-5">
          {teamData.map((team) => {
            return <TeachersCard key={team.id} team={team} />;
          })}
        </div>
      </div>
    </>
  );
};

export default Team;
