import { baseApi } from "./baseApi";

const schoolWebsitePartnerApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // * GET ALL schoolWebsitePartner FOR SPECIFIC SCHOOL --working
    getAllSchoolWebsitePartnerForSpecificSchool: builder.mutation({
      query: () => {
        return {
          url: `schoolWebsitePartner/get-all-for-school`,
          method: "POST",
          formData: true,
          headers: {
            "x-school-id": process.env.REACT_APP_SCHOOLID,
            "x-school-unique-id": process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),

    // get single
    getSingleSchoolWebsitePartner: builder.mutation({
      query: (payload) => {
        return {
          url: `schoolWebsitePartner/${payload.id}`,
          method: "GET",
          formData: true,
          headers: {
            "x-school-id": process.env.REACT_APP_SCHOOLID,
            "x-school-unique-id": process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),
  }),
});

export const {
  useGetSingleSchoolWebsitePartnerMutation,
  useGetAllSchoolWebsitePartnerForSpecificSchoolMutation,
} = schoolWebsitePartnerApi;
