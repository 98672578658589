import { baseApi } from './baseApi';

const schoolWebsiteTestimonialApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // * GET ALL schoolWebsiteFacilities FOR SPECIFIC SCHOOL --working
    getAllSchoolWebsiteFacilitiesForSpecificSchool: builder.mutation({
      query: () => {
        return {
          url: `schoolWebsiteTestimonial/get-all-for-school`,
          method: 'POST',
          formData: true,
          headers: {
            'x-school-id': process.env.REACT_APP_SCHOOLID,
            'x-school-unique-id': process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),
  }),
});

export const { useGetAllSchoolWebsiteFacilitiesForSpecificSchoolMutation } =
  schoolWebsiteTestimonialApi;
