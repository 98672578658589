import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSingleSchoolWebsiteEventMutation } from "../api/APIs/schoolWebsiteEventApi";
import HtmlParser from "../components/HtmlParser";

const EventDetails = () => {
  const { id } = useParams();
  const [eventData, setEventData] = useState({});

  const [getSingleSchoolWebsiteEvent] =
    useGetSingleSchoolWebsiteEventMutation();

  const fetchAndSetData = async () => {
    const eventResponse = await getSingleSchoolWebsiteEvent({ id }).then(
      (response) => response.data?.data
    );
    setEventData(eventResponse);
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className="section-container lg:px-16 px-4 lg:py-12 py-10 space-y-7">
        <h1>{eventData.name}</h1>
        <div className="w-full">
          <img
            className="w-full aspect-[5/2] object-cover"
            src={`${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${eventData.coverImage}`}
            alt=""
          />
        </div>
        <div>
          <p>
            <p className="text-xl">
              <span>Event on </span>
              <span className="text-red-500">{eventData.date}</span>
            </p>
            <hr className="my-2"></hr>
            <HtmlParser>{eventData.description ?? ""}</HtmlParser>
          </p>
        </div>
      </div>
    </>
  );
};

export default EventDetails;
