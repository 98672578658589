
import { baseApi } from "./baseApi";

const schoolWebsiteHistoryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // * GET ALL schoolWebsiteHistory FOR SPECIFIC SCHOOL --working
    getAllSchoolWebsiteHistoryForSpecificSchool: builder.mutation({
      query: () => {
        return {
          url: `schoolWebsiteHistory/get-all-for-school`,
          method: "POST",
          formData: true,
          headers: {
            "x-school-id": process.env.REACT_APP_SCHOOLID,
            "x-school-unique-id": process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),

    // get single
    getSingleSchoolWebsiteHistory: builder.mutation({
      query: (payload) => {
        return {
          url: `schoolWebsiteHistory/${payload.id}`,
          method: "GET",
          formData: true,
          headers: {
            "x-school-id": process.env.REACT_APP_SCHOOLID,
            "x-school-unique-id": process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),
  }),
});

export const {
  useGetSingleSchoolWebsiteHistoryMutation,
  useGetAllSchoolWebsiteHistoryForSpecificSchoolMutation,
} = schoolWebsiteHistoryApi;
