import React from 'react';
import { Link } from 'react-router-dom';
import { useGetAllSchoolWebsiteNoticeForSpecificSchoolMutation } from '../api/APIs/schoolWebsiteNoticeApi';

import HtmlParser from '../components/HtmlParser';
import { useEffect, useState } from 'react';

const Notice = () => {
  const [allNotices, setAllNotices] = useState([]);

  const [
    getAllRecordForSpecificSchool,
    { data: allRecordData, error, isLoading: isAllBookDataFetchingLoading },
  ] = useGetAllSchoolWebsiteNoticeForSpecificSchoolMutation();

  const fetchAndSetData = async () => {
    const fetchedCanteenItems = await getAllRecordForSpecificSchool().then(
      (response) => response.data?.data
    );
    if (fetchedCanteenItems !== undefined) {
      setAllNotices(fetchedCanteenItems);
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);
  return (
    <>
      <div className="lg:w-[470px] mx-auto my-12">
        <h2
          className="heading text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          ALL NOTICES
        </h2>
        <h3
          className="text__para text-center"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          Get latest informations and updates
        </h3>
      </div>
      <div>
        <div className="section-container lg:px-16 px-4 lg:py-16">
          {allNotices.map((notice) => {
            return (
              <div
                className="flexPack flex-col md:flex-row md:gap-2 gap-5 my-5 hover:shadow-lg px-1 md:py-4 py-7 border-b "
                key={notice._id}
              >
                <div className="md:w-[75%]">
                  <p className="primary-text pb-1">{notice.date}</p>
                  <h4 className="pb-5">{notice.title}</h4>
                  <div>
                    <HtmlParser>{notice.description}</HtmlParser>
                  </div>
                </div>
                <div className="w-28 md:w-28 md:ml-0 ml-auto">
                  <Link
                    to={`/noticeDetails/${notice._id}`}
                    className="w-full text-[#1e1e4b] py-3 px-2 border-[#1e1e4b] border font-semibold hover:bg-[#1e1e4b] hover:text-white text-center block"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Notice;
