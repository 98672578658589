import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSingleSchoolWebsiteNoticeMutation } from "../api/APIs/schoolWebsiteNoticeApi";
import HtmlParser from "../components/HtmlParser";

const NoticeDetails = () => {
  const { id } = useParams();
  const [noticeData, setNoticeData] = useState({});

  const [getSingleSchoolWebsiteNotice] =
    useGetSingleSchoolWebsiteNoticeMutation();

  const fetchAndSetData = async () => {
    const noticeResponse = await getSingleSchoolWebsiteNotice({ id }).then(
      (response) => response.data?.data
    );
    if (noticeResponse) {
      setNoticeData(noticeResponse);
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className='lg:w-[470px] mx-auto my-12'>
        <h2 className='primary-text heading text-center' data-aos="fade-up" data-aos-duration="1000">{noticeData.title}</h2>
        <h3 className=' text__para text-center' data-aos="fade-up" data-aos-duration="1500" >
          Notice
        </h3>
      </div>
      <div className="section-container lg:px-16 px-4 lg:py-14 py-10">
        <div>
          <p className="primary-text text-lg pb-5">{noticeData.date}</p>
          <HtmlParser>{noticeData.description ?? ""}</HtmlParser>
        </div>
      </div>
    </>
  );
};

export default NoticeDetails;
