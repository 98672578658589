import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import pimage from '../assets/page-title.jpg';
import { useGetAllSchoolWebsiteOtherSchoolInfoForSpecificSchoolMutation } from '../api/APIs/schoolWebsiteOtherSchoolInfoApi';
import HtmlParser from '../components/HtmlParser';

const Schools = () => {
  const [otherSchoolData, setOtherSchoolData] = useState([]);
  const [getAllSchoolWebsiteOtherSchoolInfoForSpecificSchool] =
    useGetAllSchoolWebsiteOtherSchoolInfoForSpecificSchoolMutation();
  const fetchAndSetData = async () => {
    const otherSchoolResponse =
      await getAllSchoolWebsiteOtherSchoolInfoForSpecificSchool().then(
        (response) => response.data?.data
      );
    if (otherSchoolResponse !== undefined) {
      setOtherSchoolData(otherSchoolResponse);
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className="lg:w-[470px] mx-auto my-12">
        <h2
          className="heading text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          OUR BLOGS
        </h2>
        <h3
          className="text__para text-center"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          Our related articles
        </h3>
      </div>
      <div className="section-container lg:px-20 px-4 lg:py-16 py-10 flex md:flex-row flex-col gap-2">
        <section className="md:w-[75%]">
          {otherSchoolData.map((school) => {
            return (
              <>
                <div className="mb-10" id={school.title}>
                  <h3>{school.title}</h3>
                  <div className=" my-4 w-full aspect-[4/2]">
                    <img
                      className="w-full h-full object-cover"
                      src={`${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${school.coverImage}`}
                      alt="primary school"
                    />
                  </div>
                  <HtmlParser>{school.description ?? ''}</HtmlParser>
                </div>
                <hr />
              </>
            );
          })}
        </section>
        <section className="md:w-[25%] sm:w-[40%] w-[60%] background p-4 rounded-md relative md:mt-0 mt-7">
          <h5 className="">Related Links</h5>
          <div className="mt-4 list-none related-links space-y-6">
            {otherSchoolData.map((school) => {
              return (
                <li>
                  <a href={`#${school.title}`}>- {school.title}</a>
                </li>
              );
            })}
            <li>
              <Link to="/admission-process">- Admissions</Link>
            </li>
            <li>
              <Link to="/scholarship">- Scholarship</Link>
            </li>
          </div>
        </section>
      </div>
    </>
  );
};

export default Schools;
