import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSingleSchoolWebsiteEcaMutation } from "../api/APIs/schoolWebsiteEcaApi";
import HtmlParser from "../components/HtmlParser";

const EcaDetails = () => {
  const { id } = useParams();
  const [ecaData, setEcaData] = useState({});

  const [getSingleSchoolWebsiteEca] =
    useGetSingleSchoolWebsiteEcaMutation();

  const fetchAndSetData = async () => {
    const ecaResponse = await getSingleSchoolWebsiteEca({ id }).then(
      (response) => response.data?.data
    );
    setEcaData(ecaResponse);
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className='lg:w-[470px] mx-auto mt-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">{ecaData.title}</h2>
        <h3 className='text__para text-center' data-aos="fade-up" data-aos-duration="1500" >
          {/* {historyData.title} */}
        </h3>
      </div>
      {/* <div className="section-container lg:px-16 px-4 lg:py-12 py-10 space-y-7">
        <h1>{ecaData.name}</h1>
        <div className="w-full">
          <img
            className="w-full aspect-[5/2] object-contain"
            src={`${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${ecaData.coverImage}`}
            alt=""
          />
        </div>
        <div>
          <p>
            <HtmlParser>{ecaData.description ?? ""}</HtmlParser>
          </p>
        </div>
      </div> */}

      <div className="section-container lg:py-10 py-7  ">
        <div
          className="md:aspect-[5/2] sm:aspect-[5/3] aspect-[5/4]  flex justify-center"
          data-aos="zoom-in"
        >
          <img
            className="w-[80%] h-full object-cover"
            src={`${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${ecaData.coverImage}`}
            alt=""
          />
        </div>
        <div className="flex justify-center text-justify">
          <div className="w-[80%] my-6 p-2 ">
            <HtmlParser>{ecaData.description ?? ""}</HtmlParser>
          </div>
        </div>

      </div>
    </>
  );
};

export default EcaDetails;
