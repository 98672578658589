import React, { useEffect, useState } from 'react';
import HtmlParser from '../components/HtmlParser';

const Facilities = () => {
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const serverBase = process.env.REACT_APP_SERVERURL;
  const endPoint =
    serverBase + '/api/v1/schoolWebsiteFacilities/get-all-for-school';

  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const response = await fetch(endPoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-school-id': process.env.REACT_APP_SCHOOLID,
            'x-school-unique-id': process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch facilities');
        }

        const { data } = await response.json();
        setFacilitiesData(data || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFacilities();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <div className="lg:w-[470px] mx-auto mt-12">
        <h2
          className="heading text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          OUR FACILITIES
        </h2>
      </div>
      <div>
        <div className="section-container lg:px-16 px-4 lg:py-16 py-5">
          {facilitiesData.length > 0 ? (
            facilitiesData.map((facility, index) => (
              <div key={index} className="section">
                <div className="md:w-[50%] md:aspect-[5/4] bg-blue-400">
                  <img
                    className="w-full h-full object-cover"
                    src={`${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${facility.coverImage}`}
                    alt={facility.title || 'Facility Image'}
                  />
                </div>
                <div className="md:w-[50%]">
                  <h3 className="pb-4">{facility.title}</h3>
                  <p>
                    <HtmlParser>{facility.description || ''}</HtmlParser>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p>No facilities available</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Facilities;
