import React from "react";
import { Link } from "react-router-dom";
const Login = () => {
  return (
    <section className="w-full flex justify-center items-center h-screen background">
      <div className="flex">
        <div className="left-section primary-bg w-80 h-[27rem] bg-[url('./assets/login_background.jpg')] bg-cover"></div>
        <div className="right-section  py-10 px-5 bg-[#fefefe] w-[27rem]">
          <h3 className="text-center">Login For Admin</h3>
          <form action="" className="space-y-8 pt-10">
            <div>
              <label htmlFor="email" className="secondary-text">
                Username
              </label>
              <input type="email" placeholder="Enter your username" />
            </div>
            <div>
              <label htmlFor="password" className="secondary-text">
                Password
              </label>
              <input type="password" placeholder="Enter your password" />
            </div>
            <button
              type="submit"
              className="primary-bg text-white w-full rounded-xl py-3 hover:opacity-90"
            >
              Login
            </button>
            <div className="text-end hover:underline">
              <Link to="/forgotPassword" className="text-sm secondary-text">
                Forgot Password?
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
