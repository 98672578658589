import React, { useEffect, useState } from 'react';
import { useGetAllSchoolWebsiteStudentsInformationForSpecificSchoolMutation } from '../api/APIs/schoolWebsiteStudentsInformationApi';
import HtmlParser from '../components/HtmlParser';

const ForStudents = () => {
  const [forStudents, setForStudents] = useState([]);

  const [getAllSchoolWebsiteStudentsInformationForSpecificSchool] =
    useGetAllSchoolWebsiteStudentsInformationForSpecificSchoolMutation();

  const fetchAndSetData = async () => {
    const forStudentsResponse =
      await getAllSchoolWebsiteStudentsInformationForSpecificSchool().then(
        (response) => response.data?.data
      );
    if (forStudentsResponse) {
      setForStudents(forStudentsResponse);
    }
  };
  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className="lg:w-[470px] mx-auto my-12">
        <h2
          className="heading text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          For Students
        </h2>
      </div>
      <div className="section-container lg:px-16 px-4 lg:py-16 py-5">
        {forStudents.map((studentsInfo) => {
          return (
            <div className="section">
              <div className="md:w-[50%] md:aspect-[5/4]">
                <img
                  className="w-full h-full object-contain"
                  src={`${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${studentsInfo.coverImage}`}
                  alt=""
                />
              </div>
              <div className="md:w-[50%]">
                <h3 className="pb-4">{studentsInfo.title}</h3>
                <p>
                  <HtmlParser>{studentsInfo.description ?? ''}</HtmlParser>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ForStudents;
