import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { setupListeners } from "@reduxjs/toolkit/query";

import { baseApi } from "./APIs/baseApi";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["store"], // place to select which state you want to persist
};

const rootReducer = combineReducers({

  [baseApi.reducerPath]: baseApi.reducer,

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,

  // * (step III)
  // todo (api query): RTK QUERY REDUCERS (keep adding below)
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      baseApi.middleware,
    ),
});

setupListeners(store.dispatch);

export default store