import React, { useEffect, useState } from "react";
import { useGetAllDepartmentRecordForSpecificSchoolMutation } from "../api/APIs/employeeDepartmentApi";
import HtmlParser from "../components/HtmlParser";

const Departments = () => {
  const [departmentData, setDepartmentData] = useState([]);
  const [getAllDepartmentRecordForSpecificSchool] =
    useGetAllDepartmentRecordForSpecificSchoolMutation();
  const fetchAndSetData = async () => {
    const departmentResponse =
      await getAllDepartmentRecordForSpecificSchool().then(
        (response) => response.data?.data
      );
    setDepartmentData(departmentResponse);
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);
  return (
    <>
      <div className='lg:w-[470px] mx-auto my-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">OUR DEPARTMENTS</h2>
        <h3 className='text__para text-center' data-aos="fade-up" data-aos-duration="1500" >
          Present within our organization
        </h3>
      </div>
      <div>
        <div className="section-container lg:px-16 px-4 lg:py-10">
          {departmentData.map((department) => {
            return (
              <div className="flex flex-col md:flex-row md:gap-5 gap-5 my-5 hover:shadow-lg px-1 py-7 border-b shadow">
                <div className="md:w-[75%]">
                  <h4 className="pb-5">{department.name}</h4>
                  <p>
                    <HtmlParser>{department.description ?? ""}</HtmlParser>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Departments;
