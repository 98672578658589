import React from "react";

const About = () => {
  return (
    <>
      <div className="w-full bg-[url('./assets/page-title.jpg')] bg-cover">
        <div className="w-full lg:py-16 py-3 bg-[#1a1a37] bg-opacity-80">
          <div className="section-container h-full lg:px-20 px-4 flex items-center ">
            <div className="lg:w-[60%]">
              <h2
                className="primary-text"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                About Us
              </h2>
              <p
                className="text-white py-6"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque
                laudantium vitae iste, quas maxime aperiam esse! Esse unde
                voluptates perferendis.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-container lg:px-16 px-4 lg:py-12 py-10 space-y-7">
        <div className="w-full">
          <img
            className="w-full aspect-[5/2] object-cover"
            src="./images/about-page.jpg"
            alt="about img"
          />
        </div>
        <div>
          <h3>About Course</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
            adipisci commodi voluptatibus deserunt dignissimos doloremque
            veritatis vitae quas est assumenda? Accusamus dolore, dolorum non
            voluptatibus rerum dignissimos veniam natus tempora error enim, eos
            quia consequuntur quod delectus minus aliquam illo vel tenetur
            quidem officia ut temporibus ad porro nostrum. Consectetur voluptate
            nemo possimus consequuntur aspernatur quis obcaecati sit cumque
            earum illum tempora, eum natus. Quaerat maiores sint dolorum omnis
            delectus dolor, impedit, quo molestiae officia odio beatae culpa
            quasi id incidunt itaque eum corrupti natus totam sunt tempore fuga
            eligendi reiciendis ipsa repudiandae. Beatae, commodi eligendi
            aliquid consequuntur autem dolore?
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
