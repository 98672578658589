//@ts-nocheck

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// You can keep both versions in sync to each other by replacing the worker version with the `pdfjs-dist` version found in the `package.json`:
import packageJson from '../../package.json';
const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];

const AdmissionPDF = ({ pdfLink }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <main className="rounded-lg overflow-hidden">
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
      >
        <div style={{ height: '600px' }}>
          <Viewer
            defaultScale={1}
            fileUrl={pdfLink}
            plugins={[defaultLayoutPluginInstance]}
          />
        </div>
      </Worker>
    </main>
  );
};

export default AdmissionPDF;
