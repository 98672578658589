import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Fragment, useEffect, useState } from 'react';

import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
// useGetAllSchoolWebsiteCalendarForSpecificSchoolMutation
import { useGetAllSchoolWebsiteCalendarForSpecificSchoolMutation } from '../api/APIs/schoolWebsiteCalendarApi';

const localizer = momentLocalizer(moment);

const CalendarPage = (props) => {
  const [
    getAllRecordForSpecificSchool,
    {
      data: allRecordData,
      error: isFetchAllRecordDataError,
      isLoading: isAllRecordFetchingLoading,
    },
  ] = useGetAllSchoolWebsiteCalendarForSpecificSchoolMutation();

  const [calendarEvents, setCalendarEvents] = useState([]);

  const myEventsList = [{ start: new Date(), end: new Date(), title: 'Hello' }];

  const fetchRecordAndSetTable = async () => {
    getAllRecordForSpecificSchool().then((res) => {
      setCalendarEvents(
        res.data.data.map((item) => {
          return {
            id: item._id,
            title: item.title,
            start: new Date(item.start),
            end: new Date(item.end),
          };
        })
      );
    });
  };

  useEffect(() => {
    fetchRecordAndSetTable();
  }, []);

  return (
    <div className="p-8">
      <Calendar
        localizer={localizer}
        events={calendarEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div>
  );
};

export default CalendarPage;
