import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSingleSchoolWebsiteProgrammeMutation } from "../api/APIs/schoolWebsiteProgrammeApi";
import HtmlParser from "../components/HtmlParser";

const CourseDetails = () => {
  const { id } = useParams()
  const [ courseData, setCourseData ] = useState({})
  
  const [ getAllSchoolWebsiteProgrammeForSpecificSchool ] = useGetSingleSchoolWebsiteProgrammeMutation()

  const fetchAndSetData = async() => {
    const courseResponse = await getAllSchoolWebsiteProgrammeForSpecificSchool({id}).then(
      response => response.data?.data
    )
    setCourseData(courseResponse)
  }

  useEffect(() => {
    fetchAndSetData()
  }, [])
  
  return (
    <>
      <div className="w-full bg-[url('./assets/page-title.jpg')] bg-cover">
        <div className="w-full lg:py-16 py-3 bg-[#1a1a37] bg-opacity-80">
          <div className="section-container h-full lg:px-20 px-4 flex items-center ">
            <div className="lg:w-[60%]">
              <h2
                className="primary-text"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Our Courses -
                <span className="text-white text-2xl"> {courseData.title}</span>
              </h2>
              <p
                className="text-white py-6"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Our courses offer a good compromise between the continuous
                assessment favoured by some universities and the emphasis placed
                on final exams by others.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-container lg:px-16 px-4 lg:py-12 py-10 space-y-7">
        <div className="w-full">
          <img
            className="w-full aspect-[5/2] object-cover"
            src={
              `${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${courseData.coverImage}`
            }
            alt=""
          />
        </div>
        <div>
          <p>
            <HtmlParser>{courseData.description ?? ""}</HtmlParser>
          </p>
          <div className="mt-10 flex gap-5 items-center">
            <div>
              <h5>{courseData.teacherName}</h5>
              <p>{courseData.position}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
