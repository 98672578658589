import { baseApi } from "./baseApi";

const employeeDepartmentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // * GET ALL employee department FOR SPECIFIC SCHOOL --working
    getAllDepartmentRecordForSpecificSchool: builder.mutation({
      query: () => {
        return {
          url: `employeeDepartment/get-all-for-school`,
          method: "POST",
          formData: true,
          headers: {
            "x-school-id": process.env.REACT_APP_SCHOOLID,
            "x-school-unique-id": process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),
  }),
});

export const {
  useGetAllDepartmentRecordForSpecificSchoolMutation,
} = employeeDepartmentApi;
