import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetAllSchoolWebsiteEcaForSpecificSchoolMutation } from "../api/APIs/schoolWebsiteEcaApi";

const ExtracurricularActivities = () => {
  const [ecaData, setEcaData] = useState([]);
  const [getAllSchoolWebsiteEcaForSpecificSchool] =
    useGetAllSchoolWebsiteEcaForSpecificSchoolMutation();
  const fetchAndSetData = async () => {
    const ecaResponse = await getAllSchoolWebsiteEcaForSpecificSchool().then(
      (response) => response.data?.data
    );
    setEcaData(ecaResponse);
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className='lg:w-[470px] mx-auto my-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">ECAs</h2>
        <h3 className='text__para text-center' data-aos="fade-up" data-aos-duration="1500" >
          Extracurricular Activities and Clubs
        </h3>
      </div>
      <div className="section-container  lg:px-16 px-4 lg:py-16 py-10">
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-x-7 lg:gap-y-16 gap-y-5">
          {ecaData.map((eca) => {
            return (
              <Link to={`/ecaDetails/${eca._id}`} className="course-card" data-aos="zoom-in">
                <div className="w-full aspect-[5/4]">
                  <img
                    className="w-full h-full object-cover"
                    src={`${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${eca.coverImage}`}
                    alt="club img"
                  />
                </div>
                <div className="p-4">
                  <h4>{eca.title}</h4>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ExtracurricularActivities;
