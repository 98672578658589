import React, { useEffect, useState } from "react";
import { useGetAllSchoolWebsiteEventForSpecificSchoolMutation } from "../api/APIs/schoolWebsiteEventApi";
import HtmlParser from "../components/HtmlParser";
import { Link } from "react-router-dom";

const Events = () => {
  const [eventData, setEventData] = useState([]);
  const [getAllSchoolWebsiteEventForSpecificSchool] =
    useGetAllSchoolWebsiteEventForSpecificSchoolMutation();

  const fetchAndSetData = async () => {
    const eventResponse =
      await getAllSchoolWebsiteEventForSpecificSchool().then(
        (response) => response.data?.data
      );
    if (eventResponse !== undefined) {
      setEventData(eventResponse);
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className='lg:w-[470px] mx-auto my-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">OUR EVENTS</h2>
        <h3 className='text__para text-center' data-aos="fade-up" data-aos-duration="1500" >
          Here are some of our upcoming or conducted events
        </h3>
      </div>
      <div className="background">
        <div className="section-container lg:px-16 px-4 lg:py-16 py-10">
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-x-7 lg:gap-y-16 gap-y-5">
            {eventData.map((event) => {
              return (
                <Link to={`/eventDetails/${event._id}`}>
                  <div className="events-page-card" data-aos="zoom-in">
                    <div className="w-full aspect-[5/4]">
                      <img
                        className="w-full h-full object-cover"
                        src={`${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${event.coverImage}`}
                        alt="event img"
                      />
                    </div>
                    <div className="py-4 px-7">
                      <div className="flex items-center primary-text gap-2 pb-4">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                            />
                          </svg>
                        </span>
                        <span className="pt-1">{event.date}</span>
                      </div>
                      <h4>{event.name}</h4>
                      <p className="py-3">
                        <HtmlParser>
                          {event.description
                            ? event.description.length > 50
                              ? event.description.slice(0, 50) + "..."
                              : event.description
                            : ""}
                        </HtmlParser>
                      </p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
