import React from "react";
import { useState, useEffect } from "react";
import { useGetAllSchoolWebsiteHistoryForSpecificSchoolMutation } from "../api/APIs/schoolWebsiteHistoryApi";
import HtmlParser from "../components/HtmlParser";

const History = () => {
  const [historyImageUrl, setHistoryImageUrl] = useState(
    "https://i.ibb.co/vVnqvfJ/sss.jpg"
  );
  const [historyData, setHistoryData] = useState({})

  const [getAllSchoolWebsiteHistoryForSpecificSchool] = useGetAllSchoolWebsiteHistoryForSpecificSchoolMutation();

  const fetchAndSetData = async () => {
    const historyResponse = await getAllSchoolWebsiteHistoryForSpecificSchool();
    if (historyResponse?.data?.data) {
      if (historyResponse.data.data.coverImage) {
        setHistoryImageUrl(
          `${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${historyResponse.data.data.coverImage}`
        );
      }
      setHistoryData(historyResponse.data.data);
    }
  }

  useEffect(() => {
    fetchAndSetData();
  }, [])
  return (
    <>
      <div className='lg:w-[470px] mx-auto mt-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">HISTORY</h2>
        <h3 className='text__para text-center' data-aos="fade-up" data-aos-duration="1500" >
          {historyData.title}
        </h3>
      </div>
      <div className="section-container lg:py-10 py-7  ">
        <div
          className="md:aspect-[5/2] sm:aspect-[5/3] aspect-[5/4]  flex justify-center"
          data-aos="zoom-in"
        >
          <img
            className="w-[80%] h-full object-cover"
            src={historyImageUrl}
            alt=""
          />
        </div>
        <div className="flex justify-center text-justify">
          <div className="w-[80%] my-6 p-2 ">
            <HtmlParser>{historyData.description ?? ""}</HtmlParser>
          </div>
        </div>

      </div>
    </>
  );
};

export default History;
