import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetAllSchoolWebsitePartnerForSpecificSchoolMutation } from "../api/APIs/schoolWebsitePartnerApi";
import config from "../utils/config";

const Partners = () => {
  const [partnerData, setPartnerData] = useState([]);

  const [getAllSchoolWebsitePartnerForSpecificSchool] =
    useGetAllSchoolWebsitePartnerForSpecificSchoolMutation();

  const fetchAndSetData = async () => {
    const partnerResponse =
      await getAllSchoolWebsitePartnerForSpecificSchool().then(
        (response) => response.data?.data
      );
    if (partnerResponse !== undefined) {
      setPartnerData(partnerResponse);
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);
  return (
    <>
      <div className='lg:w-[470px] mx-auto mt-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">OUR PARTNERS</h2>
      </div>
      <div className="section-container lg:px-20 px-4 lg:py-10 py-7">
        <h3 className="mb-6">Our Partners</h3>
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-x-7 lg:gap-y-16 gap-y-5">
          {partnerData.map((partner) => {
            return (
              <Link className="teachers-page-card" to={partner.partnerLink ? partner.partnerLink : "#"} target="_blank" data-aos="zoom-in">
                <div className="w-full aspect-[5/4]">
                  <img
                    className="w-full h-full object-cover"
                    src={config.getUrlFromName(partner)}
                    alt="event img"
                  />
                </div>
                <div className="p-4 space-y-3">
                  <span>
                    <h4>{partner.name}</h4>
                    <p>{partner.typeOfPartner}</p>
                  </span>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Partners;
