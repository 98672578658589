import React, { useEffect, Fragment, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import {
  usePostSchoolAdmissionFormMutation,
  useGetSingleSchoolAdmissionFormClassNamesMutation,
} from '../api/APIs/schoolWebsiteAdmissionFormApi';
import { Dialog, Transition } from '@headlessui/react';
import AdmissionPDF from '../components/AdmissionPDF';
import {
  FaFilePdf,
  FaFileExcel,
  FaFileWord,
  FaFileImage,
  FaFilePowerpoint,
  FaFile,
} from 'react-icons/fa';

const ApplyForAdmissionForm = () => {
  // for pdf viewer  start here
  const [pdfLink, setPdfLink] = useState('');
  const [pdfTitle, setPdfTitle] = useState('');
  let [isOpenViewPDFModel, setIsOpenViewPDFModel] = useState(false);

  function openViewPDFModel() {
    setIsOpenViewPDFModel(true);
  }

  function closeViewPDFModel() {
    setIsOpenViewPDFModel(false);
  }

  // for pdf viewer  end here

  const [classNames, setClassNames] = useState([]);
  const [attachments, setAttachments] = useState({});
  const [admissionIds, setAdmissionIds] = useState({});
  const [files, setFiles] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [infoResponse] = useGetSingleSchoolAdmissionFormClassNamesMutation();
  const [postSchoolAdmissionForm] = usePostSchoolAdmissionFormMutation();

  const fetchAndSetData = async () => {
    try {
      const response = await infoResponse();
      const informationResponse = response.data?.data;
      console.log('informationResponse:', informationResponse);

      const classNames = informationResponse.map((info) => info.className);
      const attachments = informationResponse.reduce((acc, info) => {
        if (!acc[info.className]) acc[info.className] = [];
        acc[info.className].push(info.attachment_path);
        return acc;
      }, {});
      const admissionIds = informationResponse.reduce((acc, info) => {
        acc[info.className] = info.admissionId;
        return acc;
      }, {});

      setClassNames(classNames);
      setAttachments(attachments);
      setAdmissionIds(admissionIds);
    } catch (error) {
      console.error('Error fetching class names:', error);
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setFiles([...files, ...acceptedFiles]);

      if (rejectedFiles?.length) {
        rejectedFiles.map(({ file, errors }) =>
          errors.map((e) => toast.error(e.code))
        );
      }
    },
    [files]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('phoneNumber', phoneNumber);
    files.forEach((file) => {
      formData.append('s3files', file);
    });
    let currentAdmissionId = admissionIds[selectedClass];

    try {
      const response = await postSchoolAdmissionForm({
        admissionId: currentAdmissionId,
        formData,
      });
      if (response.data.success) {
        toast.success('Application submitted.');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/*': ['.pdf'],
    },
  });

  const formatFileName = (fileName) => {
    const formattedName = fileName.split('_');
    let temp = '';
    let temp2 = '';

    if (formattedName.length > 2) {
      for (var i = 0; i < formattedName.length - 2; i++) {
        temp = temp.concat(formattedName[i + 2]).concat('_');
        temp2 = temp.slice(0, -1);
      }
      return temp2;
    }

    return formattedName[2];
  };

  // for pdf viewer
  const getFileIcon = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();

    switch (fileExtension) {
      case 'pdf':
        return <FaFilePdf />;
      case 'xlsx':
      case 'xls':
        return <FaFileExcel />;
      case 'docx':
      case 'doc':
        return <FaFileWord />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <FaFileImage />;
      case 'pptx':
      case 'ppt':
        return <FaFilePowerpoint />;
      default:
        return <FaFile />;
    }
  };

  return (
    <>
      <Transition appear show={isOpenViewPDFModel} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeViewPDFModel}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full max-w-4xl
               transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                >
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 mb-3"
                  >
                    {pdfTitle}
                  </Dialog.Title>
                  <AdmissionPDF pdfLink={pdfLink} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div className="lg:w-[470px] mx-auto mt-12">
        <h2
          className="heading text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          APPLY NOW!
        </h2>
        <p
          className="text__para text-center text-gray-500"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          Apply for your admission from here. Fill in the form below to apply
          for admission. We will get back to you as soon as possible.
        </p>
      </div>
      {/* <div className="w-full bg-[url('./assets/page-title.jpg')] bg-cover">
        <div className="w-full lg:py-16 py-3 bg-[#1a1a37] bg-opacity-80">
          <div className="section-container h-full lg:px-20 px-4 flex items-center">
            <div className="lg:w-[60%]">
              <h2
                className="text-white"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Apply For Admission
              </h2>
              <p
                className="text-white py-6"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Apply for your admission from here. Fill in the form below to
                apply for admission. We will get back to you as soon as
                possible.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="section-container lg:px-20 px-4 lg:py-10 py-7">
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label
              htmlFor="phoneNumber"
              className="block text-sm font-medium text-gray-700 mb-4"
            >
              Phone Number
            </label>
            <input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              className="mt-1 mb-4 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              placeholder="Enter your phone number"
              required
            />

            <label
              htmlFor="class"
              className="block text-sm font-medium text-gray-700 mb-4"
            >
              Select Class
            </label>
            <select
              id="class"
              name="class"
              value={selectedClass}
              onChange={handleClassChange}
              className="mt-1 mb-4 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              required
            >
              <option value="" disabled>
                Select a class
              </option>
              {classNames.length > 0 ? (
                classNames.map((className, index) => (
                  <option key={index} value={className}>
                    {className}
                  </option>
                ))
              ) : (
                <option>Loading classes...</option>
              )}
            </select>

            {selectedClass && attachments[selectedClass] && (
              <div>
                {attachments[selectedClass].map(
                  (attachmentPath, attachment, index) => {
                    console.log('attachmentPath', attachmentPath);
                    return (
                      <a
                        key={index}
                        className="cursor-pointer inline-flex items-center px-4 py-2 border border-black text-sm font-medium rounded-md text-black bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary mb-4"
                        onClick={() => {
                          setPdfLink(attachmentPath);
                          setPdfTitle(formatFileName(attachmentPath));
                          openViewPDFModel();
                        }}
                      >
                        {getFileIcon(attachmentPath)}
                        {formatFileName(attachmentPath)}
                      </a>
                    );
                  }
                )}
              </div>
            )}

            <div
              {...getRootProps()}
              className="dropzone mt-4 mb-4 p-4 border-2 border-dashed border-gray-300 bg-gray-100 rounded-md"
            >
              <input {...getInputProps()} />
              <p>Drag & drop files here, or click to select files</p>
            </div>

            {files.length > 0 && (
              <div className="mt-4 mb-4">
                <h4>Uploaded Files:</h4>
                <ul>
                  {files.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            )}

            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-black text-sm font-medium rounded-md text-black bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary mb-4"
            >
              Submit
            </button>
            <p className="mt-4 text-sm text-gray-500">
              By submitting the form, you agree to our terms of service. <br />
              We will get back to you as soon as possible.
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default ApplyForAdmissionForm;
