import React, { useEffect, useState } from 'react';
import { useGetAllSchoolWebsiteInformationForSpecificSchoolMutation } from '../api/APIs/schoolWebsiteInformationApi';
import HtmlParser from '../components/HtmlParser';
import { usePostAddSchoolWebsiteContactMutation } from '../api/APIs/schoolWebsiteContactApi';
import { toast } from 'react-toastify';

const ContactUs = () => {
  const [formData, setFormData] = useState(new FormData());
  const [informationData, setInformationData] = useState({});
  const [getAllSchoolWebsiteInformationForSpecificSchool] =
    useGetAllSchoolWebsiteInformationForSpecificSchoolMutation();
  const [postAddSchoolWebsiteContact] =
    usePostAddSchoolWebsiteContactMutation();

  const fetchAndSetData = async () => {
    const informationResponse =
      await getAllSchoolWebsiteInformationForSpecificSchool().then(
        (response) => response.data?.data
      );
    setInformationData(informationResponse);
    console.log(informationData);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (Array.isArray(value)) {
      return;
    }
    formData.set(name, value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await postAddSchoolWebsiteContact(formData);

      if (response.error) {
        console.log(response.error.data.message);
        throw new Error(response.error);
      }

      if (response.data.success) {
        console.log(response.data, 'your are sucess');
        toast.success('Record added successfully');
        document.getElementById('contactForm').reset();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || 'An Error Occured');
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);
  return (
    <>
      <div className="lg:w-[470px] mx-auto my-12">
        <h2
          className="heading text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          CONTACT US
        </h2>
        <h3
          className="text__para text-center"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          Reach us through following
        </h3>
      </div>
      <div className="background">
        <div className="section-container lg:px-16 px-4 pb-10">
          <div className="flex lg:flex-row flex-col lg:items-start justify-between  lg:gap-4 gap-6">
            <div className="">
              <form
                id="contactForm"
                action=""
                className="contact-form mt-5 space-y-5 w-[80%]"
                onSubmit={handleSubmit}
              >
                <input
                  type="text"
                  name="name"
                  className="w-full py-3 bg-white text-lg px-2"
                  placeholder="Enter your name"
                  onChange={handleInputChange}
                />
                <input
                  type="email"
                  name="email"
                  className="w-full py-3 bg-white text-lg px-2"
                  placeholder="Enter your email"
                  onChange={handleInputChange}
                />
                <input
                  type="subject"
                  name="subject"
                  className="w-full py-3 bg-white text-lg px-2"
                  placeholder="Enter your subject"
                  onChange={handleInputChange}
                />
                <textarea
                  placeholder="Enter your message"
                  className="w-full text-lg p-2"
                  name="message"
                  id=""
                  cols="20"
                  rows="5"
                  onChange={handleInputChange}
                ></textarea>
                <button className="secondary-bg px-4 py-4 text-white">
                  Send Message
                </button>
              </form>
            </div>
            <div className="mt-5 text-right">
              <HtmlParser>
                {informationData.contactDescription ?? ''}
              </HtmlParser>
            </div>
          </div>
        </div>
      </div>

      {/* Google Map Embed */}
    </>
  );
};

export default ContactUs;
