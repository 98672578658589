import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSingleSchoolWebsiteTeamMutation } from "../api/APIs/schoolWebsiteTeamApi";
import HtmlParser from "../components/HtmlParser";

const TeachersDetails = () => {
  const { id } = useParams();
  const [getSingleSchoolWebsiteTeam] = useGetSingleSchoolWebsiteTeamMutation();
  const [teamImageUrl, setTeamImageUrl] = useState(
    "https://i.ibb.co/vVnqvfJ/sss.jpg"
  );
  const [teamData, setTeamData] = useState({});

  const fetchAndSetData = async () => {
    const teamResponse = await getSingleSchoolWebsiteTeam({ id });
    console.log(teamResponse);
    if (teamResponse?.data?.data) {
      if (teamResponse.data.data.coverImage) {
        setTeamImageUrl(
          `${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${teamResponse.data.data.coverImage}`
        );
      }
      setTeamData(teamResponse.data.data);
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className="section-container lg:px-16 px-4 lg:py-16 py-10">
        <div className="md:flex justify-center gap-5">
          <div className="md:w-[40%] w-full aspect-[5/3]">
            <img
              className="w-full h-full mx-auto object-cover"
              src={teamImageUrl}
              alt="teacher img"
            />
          </div>
          <div className="md:w-[60%] md:pl-5 md:pt-0 pt-6">
            <h3 className="pb-4">{teamData.name}</h3>
            <p className="font-semibold text-xl">{teamData.position}</p>
            <p>
              <HtmlParser>{teamData.description ?? ""}</HtmlParser>
            </p>
            <div className="user-info flex flex-wrap lg:gap-0 gap-6 justify-between pt-10">
              <div className="space-y-6">
                <h3 className="pb-4">CONTACT INFO:</h3>
                <div className="align-userInfo">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                      />
                    </svg>
                  </span>
                  <span className="">{teamData.email}</span>
                </div>
                <div className="align-userInfo">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                      />
                    </svg>
                  </span>
                  <span>{teamData.phoneNumber}</span>
                </div>
                <div className="align-userInfo">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
                        fill="#5c5c77"
                      />
                    </svg>
                  </span>
                  <span>{teamData.facebookUrl}</span>
                </div>
                <div className="align-userInfo">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z"
                        fill="#5c5c77"
                      />
                    </svg>
                  </span>
                  <span>{teamData.linkedlnUrl}</span>
                </div>
                <div className="align-userInfo">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                      />
                    </svg>
                  </span>
                  <span>{teamData.location}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className="pt-16 pb-5">Biography</h3>
          <p>
            <HtmlParser>{teamData.biography ?? ""}</HtmlParser>
          </p>
        </div>
      </div>
    </>
  );
};

export default TeachersDetails;
