import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';
import About from './pages/About';
import Programme from './pages/Programme';
import Events from './pages/Events';
import Teachers from './pages/Teachers';
import Login from './pages/Login';
import TeachersDetails from './pages/TeachersDetails';
import NoticeDetails from './pages/NoticeDetails';
import Notice from './pages/Notice';
import ContactUs from './pages/ContactUs';
import CourseDetails from './pages/CourseDetails';
import Gallery from './pages/Gallery';
import Scholarship from './pages/Scholarship';
import Facilities from './pages/Facilities';
import Schools from './pages/Schools';
import Departments from './pages/Departments';
import ExtracurricularActivities from './pages/ExtracurricularActivities';
import AdmissionProcess from './pages/AdmissionProcess';
import History from './pages/History';
import Team from './pages/Team';
import ApplyForAdmissionForm from './pages/ApplyForAdmissionForm';
import TermsAndConditions from './pages/TermsAndConditions';
import Values from './pages/Values';
import Partners from './pages/Partners';
import ForStudents from './pages/ForStudents';
import ForParents from './pages/ForParents';
import ForTeachers from './pages/ForTeachers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EventDetails from './pages/EventDetails';
import EcaDetails from './pages/EcaDetails';
import CalendarPage from './pages/CalendarPage';

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/programme" element={<Programme />} />
        <Route exact path="/courseDetails/:id" element={<CourseDetails />} />
        <Route exact path="/eventDetails/:id" element={<EventDetails />} />
        <Route exact path="/ecaDetails/:id" element={<EcaDetails />} />
        <Route exact path="/events" element={<Events />} />
        <Route exact path="/teacher" element={<Teachers />} />
        <Route exact path="/teacherDetail/:id" element={<TeachersDetails />} />
        <Route exact path="/notice" element={<Notice />} />
        <Route exact path="/noticeDetails/:id" element={<NoticeDetails />} />
        <Route exact path="/contact" element={<ContactUs />} />
        <Route exact path="/calendar" element={<CalendarPage />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/admission-process" element={<AdmissionProcess />} />
        <Route exact path="/scholarship" element={<Scholarship />} />
        <Route exact path="/facilities" element={<Facilities />} />
        <Route
          exact
          path="/apply-for-admission-form/:id"
          element={<ApplyForAdmissionForm />}
        />
        <Route exact path="/schools" element={<Schools />} />
        <Route exact path="/departments" element={<Departments />} />
        <Route
          exact
          path="/extracurricular-activities"
          element={<ExtracurricularActivities />}
        />
        <Route exact path="/history" element={<History />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/terms-condition" element={<TermsAndConditions />} />
        <Route exact path="/values" element={<Values />} />
        <Route exact path="/partners" element={<Partners />} />
        <Route exact path="/for-students" element={<ForStudents />} />
        <Route exact path="/for-parents" element={<ForParents />} />
        <Route exact path="/for-teachers" element={<ForTeachers />} />
        <Route exact path="/login" element={<Login />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
