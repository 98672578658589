import { baseApi } from "./baseApi";

const schoolWebsiteNoticeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // * GET ALL schoolWebsiteNotice FOR SPECIFIC SCHOOL --working
    getAllSchoolWebsiteNoticeForSpecificSchool: builder.mutation({
      query: () => {
        return {
          url: `schoolWebsiteNotice/get-all-for-school`,
          method: "POST",
          formData: true,
          headers: {
            "x-school-id": process.env.REACT_APP_SCHOOLID,
            "x-school-unique-id": process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),

    // get single
    getSingleSchoolWebsiteNotice: builder.mutation({
      query: (payload) => {
        return {
          url: `schoolWebsiteNotice/${payload.id}`,
          method: "GET",
          formData: true,
          headers: {
            "x-school-id": process.env.REACT_APP_SCHOOLID,
            "x-school-unique-id": process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),
  }),
});

export const {
  useGetSingleSchoolWebsiteNoticeMutation,
  useGetAllSchoolWebsiteNoticeForSpecificSchoolMutation,
} = schoolWebsiteNoticeApi;
