import React from 'react';
import { Link } from 'react-router-dom';
import HtmlParser from './HtmlParser';
const CourseCard = ({ programme }) => {
  return (
    <div className="course-card" data-aos="zoom-in">
      <div className="w-full aspect-[5/4]">
        <img
          className="w-full h-full object-cover"
          src={`${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${programme.coverImage}`}
          alt="event img"
        />
      </div>
      <div className="p-4">
        <div className="flex items-center primary-text gap-2 pb-4">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
          </span>
          <span className="pt-1">{programme.date}</span>
        </div>
        <h4>{programme.title}</h4>
        <p className="py-3 leading-6"></p>
        <div className="py-4">
          <Link
            to={`/courseDetails/${programme._id}`}
            className="bg-white text-[#fda507] px-3 py-3 border-[#fda507] border font-semibold hover:bg-[#fda507] hover:text-white"
          >
            Read about our programs
          </Link>
        </div>
      </div>
    </div>
  );
};
export default CourseCard;
