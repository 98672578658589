import React, { useEffect, useState } from "react";
import { useGetAllSchoolWebsiteValuesForSpecificSchoolMutation } from "../api/APIs/schoolWebsiteValuesApi";
import HtmlParser from "../components/HtmlParser";

const Values = () => {
  const [valueData, setValueData] = useState({});
  const [valueImageUrl, setValueImageUrl] = useState(
    "https://i.ibb.co/vVnqvfJ/sss.jpg"
  );

  const [getAllSchoolWebsiteValuesForSpecificSchool] =
    useGetAllSchoolWebsiteValuesForSpecificSchoolMutation();

  const fetchAndSetData = async () => {
    const valueResponse = await getAllSchoolWebsiteValuesForSpecificSchool();
    if (valueResponse?.data?.data) {
      if (valueResponse.data.data.coverImage) {
        setValueImageUrl(
          `${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${valueResponse.data.data.coverImage}`
        );
      }
      setValueData(valueResponse.data.data);
    }
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <>
      <div className='lg:w-[470px] mx-auto mt-12'>
        <h2 className='heading text-center' data-aos="fade-up" data-aos-duration="1000">VALUES</h2>
        <h3 className='text__para text-center' data-aos="fade-up" data-aos-duration="1500" >
          {valueData.title}
        </h3>
      </div>
      <div className="section-container lg:py-10 py-7  ">
        <div
          className="md:aspect-[5/2] sm:aspect-[5/3] aspect-[5/4]  flex justify-center"
          data-aos="zoom-in"
        >
          <img
            className="w-[80%] h-full object-cover"
            src={valueImageUrl}
            alt=""
          />
        </div>
        <div className="flex justify-center text-justify">
          <div className="w-[80%] my-6 p-2 ">
            <HtmlParser>{valueData.description ?? ""}</HtmlParser>
          </div>
        </div>

      </div>
    </>
  );
};

export default Values;
