import { baseApi } from "./baseApi";

const schoolWebsiteHomePageSecondApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // * GET ALL schoolWebsiteHomePageSecond FOR SPECIFIC SCHOOL --working
    getAllSchoolWebsiteHomePageSecondForSpecificSchool: builder.mutation({
      query: () => {
        return {
          url: `schoolWebsiteHomePageSecond/get-all-for-school`,
          method: "POST",
          formData: true,
          headers: {
            "x-school-id": process.env.REACT_APP_SCHOOLID,
            "x-school-unique-id": process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),

    // get single
    getSingleSchoolWebsiteHomePageSecond: builder.mutation({
      query: (payload) => {
        return {
          url: `schoolWebsiteHomePageSecond/${payload.id}`,
          method: "GET",
          formData: true,
          headers: {
            "x-school-id": process.env.REACT_APP_SCHOOLID,
            "x-school-unique-id": process.env.REACT_APP_SCHOOLUNIQUEID,
          },
        };
      },
    }),
  }),
});

export const {
  useGetSingleSchoolWebsiteHomePageSecondMutation,
  useGetAllSchoolWebsiteHomePageSecondForSpecificSchoolMutation,
} = schoolWebsiteHomePageSecondApi;
