import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useGetAllSchoolWebsiteStatsForSpecificSchoolMutation } from '../api/APIs/schoolWebsiteStatsApi';
import { toast } from 'react-toastify';

const SchoolStats = () => {
  const [stats, setStats] = useState([]);
  const [getStats, { data, error, isLoading }] =
    useGetAllSchoolWebsiteStatsForSpecificSchoolMutation();

  useEffect(() => {
    getStats()
      .unwrap()
      .then((res) => {
        console.log('school stats', res.data);
        const newStats = res.data.map((stat, index) => ({
          id: index + 1,
          title: stat.title,
          value: stat.count,
        }));
        setStats(newStats);
      })
      .catch((err) => {
        toast.error('Failed to fetch school stats');
      });
  }, []);

  return (
    <section className="py-8 bg-gray-100">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-center text-4xl font-bold py-8">
          School Statistics
        </h2>

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="p-4 bg-white shadow rounded text-center"
            >
              <CountUp
                start={0}
                end={stat.value}
                duration={2.5}
                separator=","
                className="text-3xl font-bold text-gray-900"
              />
              <p className="mt-1 text-gray-600 text-sm">{stat.title}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SchoolStats;
