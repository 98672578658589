import React from "react";
import { createRoot } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import "./style.css";
import { Providers } from "./api/Provider";
import App from "./App";

AOS.init();

const root = createRoot(document.getElementById("root"));
root.render(
  <Providers>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Providers>
);
