import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const TeachersCard = ({ team }) => {
  console.log('TEAM ==', team);
  const [teamImageUrl, setTeamImageUrl] = useState(
    'https://i.ibb.co/vVnqvfJ/sss.jpg'
  );

  useEffect(() => {
    if (team.coverImage) {
      setTeamImageUrl(
        `${process.env.REACT_APP_SERVERURL}/storage/${process.env.REACT_APP_SCHOOLUNIQUEID}/${team.coverImage}`
      );
    }
  }, []);

  return (
    <div className="max-w-xs w-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <Link
        className="teachers-page-card"
        to={`/teacherDetail/${team._id}`}
        data-aos="zoom-in"
      >
        <div className="w-full aspect-[5/4]">
          <img
            className="w-full h-full object-cover"
            src={teamImageUrl}
            alt="teacher"
          />
        </div>
      </Link>

      <div className="p-4 space-y-3">
        <span>
          <h4 className="text-lg font-semibold">{team.name}</h4>
          <p className="text-sm text-gray-600">{team.position}</p>
        </span>
        <div className="flex gap-2">
          {team.facebookUrl && (
            <a
              href={team.facebookUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6"
                viewBox="0 0 512 512"
              >
                <path
                  d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
                  fill="#1e1e4b"
                />
              </svg>
            </a>
          )}
          {team.linkedlnUrl && (
            <a
              href={team.linkedlnUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6"
                viewBox="0 0 512 512"
              >
                <path
                  d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"
                  fill="#1e1e4b"
                />
                <path
                  d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z"
                  fill="#1e1e4b"
                />
              </svg>
            </a>
          )}
          {team.email && (
            <a href={`mailto:${team.email}`}>
              <svg
                className="w-6"
                data-name="1-Email"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <path
                  fill="#1e1e4b"
                  d="M29 4H3a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h26a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-.72 2L16 14.77 3.72 6zM30 25a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.23l13.42 9.58a1 1 0 0 0 1.16 0L30 7.23z"
                />
              </svg>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeachersCard;
